import './footer-wrapper.scss'
import Link from '@/core/Link'
import Logo from '../../Header/HeaderTop/Logo'

export default function FooterWrapper() {
  return (
    <div className="footer-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="wrapper-figures">
              <figure className="figure-2">
                <Logo fill="black" width={150} height={95} />
              </figure>
            </div>
            <div className="wrapper follow">
              <h2 className="column-title">Follow Us:</h2>
              <div className=" footer-wrapper-list">
                <ul className="social-icons">
                  <li className="facebook social_block">
                    <a
                      href="https://www.facebook.com/VedistryIndia/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="icon icon-sm icon--white bi bi-facebook"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                      </svg>
                    </a>
                  </li>
                  <li className="instagram social_block">
                    <a
                      href="https://www.instagram.com/vedistryindia/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="icon icon-sm icon--white bi bi-instagram"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                      </svg>
                    </a>
                  </li>
                  <li className="youtube social_block">
                    <a
                      href="https://www.youtube.com/channel/UCiuOPYdGI-zI8N6hznlu6NQ"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="icon icon-sm icon--white bi bi-youtube"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                      </svg>
                    </a>
                  </li>
                  <li className="twitter social_block">
                    <a
                      href="https://twitter.com/vedistryindia1"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="icon icon-sm icon--white bi bi-twitter-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </a>
                  </li>
                  <li className="linkedin social_block">
                    <a
                      href="https://www.linkedin.com/company/vedistry-pvt-ltd/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="icon icon-sm icon--white bi bi-linkedin"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="wrapper">
              <h2 className="column-title">Products</h2>
              <div className="footer-wrapper-list">
                {/* <ul>
                <li className='page-redirect'>
                  <Link href="/shop/category/skin"> Skin Care Basics</Link>
                </li>
              </ul>
              <ul>
                <li className='page-redirect'>
                  <Link href="/shop/category/bath-body"> Bath and  Body Basics </Link>
                </li>
              </ul> */}
                <ul>
                  <li className="page-redirect">
                    <Link href="/shop/category/immunity-throat-care">
                      Immunity & Throat Care
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className="page-redirect">
                    <Link href="/shop/concern/joint-health">Joint Health</Link>
                  </li>
                </ul>
                <ul>
                  <li className="page-redirect">
                    <Link href="/shop/category/digestive-care">
                      Digestive Care
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className="page-redirect">
                    <Link href="/shop/category/woman-health-care">
                      Woman Health Care
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className="page-redirect">
                    <Link href="/shop/category/personal-care">
                      Personal Care
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="wrapper">
              <h2 className="column-title">Menu</h2>
              <div className=" footer-wrapper-list">
                <ul>
                  <li className="page-redirect">
                    <Link
                      href="/cancellation-policy"
                      data-action="cancellation"
                    >
                      {' '}
                      Returns & Cancellation Policy
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className="page-redirect">
                    <Link href="/terms-conditions"> Terms & Conditions</Link>
                  </li>
                </ul>
                <ul>
                  <li className="page-redirect">
                    <Link href="/privacy-policy"> Privacy Policy </Link>
                  </li>
                </ul>
                <ul>
                  <li className="page-redirect">
                    <Link href="/disclaimer">Disclaimer </Link>
                  </li>
                </ul>
                <ul>
                  <li className="page-redirect">
                    <Link href="/contact-us"> Contact </Link>
                  </li>
                  <li className="page-redirect">
                    <Link href="/health-points-terms-conditions">
                      Health Points Terms & Conditions{' '}
                    </Link>
                  </li>
                  <li className="page-redirect">
                    <Link href="/healthmantra-privacy-policy">
                      Health Mantra Privacy Policy & Disclaimer
                    </Link>
                  </li>
                  <li className="page-redirect">
                    <Link href="/csr-policy">
                      <abbr title="Corporate Social Responsibility (CSR) Policy">CSR</abbr> Policy
                    </Link>
                  </li>
                </ul>
                {/* <ul>
                <li className='page-redirect'>
                  <Link href='/csr'> Vedistry: Adopt an NGO initiative </Link>
                </li>
              </ul> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="wrapper">
              <div className="wrapper-info">
                <div>
                  <p className="mb-10">
                    <b>Marketing Office</b>
                  </p>
                  <p>
                    Vedistry Pvt. Ltd.,
                    <br />
                    501/A, Poonam Chambers, Dr. Annie Besant Road,
                    <br />
                    Worli, Mumbai-400 018
                  </p>
                  {/* <p><b>Registered Office</b></p>
                <p>21, Evergreen Industrial Estate, Shakti mills lane,<br />
                  Off Dr. E. Moses Road, Mahalaxmi, Mumbai-400011</p> */}
                  <div className="legal1">
                    <p className="mb-10">
                      <b>Country of Origin:</b> India
                    </p>
                    <p>
                      <b>Manufacturer:</b> Charak Pharma Pvt Ltd, 21, Evergreen
                      Industrial Estate, 2nd Floor, Opposite-Dr. E. Moses Road,
                      Shakti Mills Ln, Lower Parel, Mumbai, Maharashtra 400011
                    </p>
                  </div>
                  <p className="phone">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width={16}
                      height={16}
                      className="icon icon-sm icon--white w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <a className="first-phone" href="tel:8657451581 ">
                      +91 8657451581 <br /> (Monday – Friday : 9.00 AM to 6.00
                      PM){' '}
                    </a>
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-envelope-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                    </svg>
                    <a
                      className="email-width"
                      href="mailto:customercare@vedistry.com"
                    >
                      {' '}
                      customercare@vedistry.com
                    </a>
                    <div style={{ marginTop: '2px' }} />
                    <p className="phone">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width={16}
                        height={16}
                        className="icon icon-sm icon--white w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                          clipRule="evenodd"
                        />
                      </svg>

                      <a className="first-phone" href="tel:8657451582 ">
                        +91 8657451582{' '}
                      </a>
                    </p>
                  </p>
                  <p className="chat-with">
                    <span>Chat Online with us : 11 AM – 5 PM </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
